.logout-warning-modal {
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 0;

    .modal-content {
        border-radius: 15px;

        .logout-warning-wrapper {
            height: unset;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 40px 150px;

            .logout-warning-title {
                margin: unset;
                width: unset;
                margin-bottom: 20px;
                flex-grow: 2;

                p {
                    font-weight: 600;
                }
            }

            .logout-warning-content-wrap {
                display: flex;
                gap: 20px;
                flex-grow: 3;
                align-items: center;
                flex-direction: row;

                .logout-warning-time-counter {
                    font-size: 30px;
                    font-weight: 600;
                }

                .logout-warning-border {
                    border-right-width: 1px;
                    border-right-style: solid;
                    height: 60px;

                }

                .logout-warning-message-content {
                    width: unset;
                    text-align: left;

                    p {
                        margin: unset;
                    }
                }
            }   
            .still-there-button-wrapper {
                display: flex; 
                align-items: center;
                width: 100%;
                justify-content: center;

                .im-still-here-button {
                    background-color: var(--v-color-sea-80);
                    border-color: var(--v-color-sea-80);
                    color: white;
                    box-shadow: unset; 
                    width: 50%;
                    margin-left: 0;

                    &:hover{
                        background-color: var(--v-color-sea-90);
                        border-color: var(--v-color-sea-90);
                        color:white;
                        box-shadow: unset;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 990px) and (min-width: 768px) {
    .logout-warning-modal {
        .modal-content {
            .logout-warning-wrapper {
                padding: 40px 75px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
        .logout-warning-modal {
            width: 370px;
            
            .modal-content {
                width: 90%;
            
            .logout-warning-wrapper {
                padding: 20px 20px;
                
                .logout-warning-title {
                    margin-right: auto;
                    margin-bottom: 10px;
                    
                    p {
                        margin: unset;
                    }
                }
                
                .logout-warning-content-wrap {
                    flex-direction: column;
                    margin-bottom: 10px;
                    
                    .logout-warning-border{
                        border-right-width: unset;
                        border-right-style: unset;
                        height: 0;
                        border-bottom-style: solid;
                        border-bottom-width: 2px;
                        width: 40%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 379px) {
    .logout-warning-modal {
        width: 100%;
    }
}

.board-title {
    font-size: 23px;
    line-height: 1.25;
}
