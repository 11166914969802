@use 'node_modules/@engineering/vpgroove-angular/src/styles/vpgroove-global-styles.scss';
@use './assets/style/logout-warning-modal.scss';
@use './assets/style/fonts.scss';
@use './assets/style/message-center-pane.scss';
@use '../../../../node_modules/plyr/dist/plyr.css';

// This overrides FileStack's default styling due to the bug in the FileStack picker component
// FileStack's error message is shown on top of the page, instead on top of the user screen
#__filestack-picker {
    .fsp-picker-holder {
        .fsp-notifications__container {
            position: fixed !important;
        }
    }
}

// This CSS is moved here from webchat-link-pha.component.css due to a bug with the Cognigy WebChat (the floating support chat icon wasn't being hidden)
// Will be removed after webchat-link-pha.component is refactored
@media screen and (min-width: 576px) {
    [data-cognigy-webchat-root] {
        position: relative !important;

        left: auto !important;
        top: auto !important;

        overflow: visible !important;
        width: auto !important;
        height: 100% !important;
    }

    [data-cognigy-webchat-root] [data-cognigy-webchat] {
        left: auto !important;
        top: auto !important;

        bottom: 32px !important;
        right: 32px !important;

        width: 380px !important;
        height: 580px !important;

        border-radius: 16px !important;
    }
}

body {
    background-image: url('./assets/img/Pebble-BG-Light.png');
    background-repeat: repeat;
    border: none;
    margin: 0;
    font-family: 'Open Sans', sans-serif;

    a:focus-visible {
        outline-offset: 5px;
    }
}

#page-wrapper {
    background-image: url('./assets/img/Pebble-BG-Light.png');

    &.with-gradient {
        background:linear-gradient(#fff 4.53%, var(--VPGrooveColor-BodyBackground) 60.16%)
    }
}

@media(min-width:768px) {
  #page-wrapper {
      flex: 1;
      padding: 0;
      border: none;

      &.with-navigation {
          padding-bottom: 69px;
          width: 100%;
          margin: 0 auto;

          &.with-transform {
              padding-bottom: 0;
          }
      }

      .steps-wrapper {
          margin-bottom: 170px;
      }
  }

  #page-wrapper.collapsed {
      margin: 0 0 0;
  }

  .page-content {
      opacity: 0.3;
      -webkit-transition: opacity .4s linear;
      -moz-transition: opacity .4s linear;
      -o-transition: opacity .4s linear;
      transition: opacity .4s linear;
  }

  .page-content-ease-in {
      opacity: 1;
  }
}

.app-holder {
    position: relative;
    min-height: 100vh;
    height: auto;
    margin: 0 auto -50px;
    overflow-y: clip;
    display: flex;
    flex-direction: column;
}

.footer-bottom-position {
    margin-top: auto;
}

.nr-tracking-pixel {
    min-width: 1px;
    max-width: 1px;
    min-height: 1px;
    max-height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    visibility: hidden;
}
