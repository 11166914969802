@use "sass:color";
@forward 'rosetta';
@use "rosetta";

// rosetta color operations
$primary-light: color.adjust(rosetta.$primary-standard, $lightness: 15%);
$primary-dark: color.adjust(rosetta.$primary-standard, $lightness: -15%);

$secondary-light: color.adjust(rosetta.$secondary-standard, $lightness: 15%);
$secondary-dark: color.adjust(rosetta.$secondary-standard, $lightness: -15%);

$white-shadow: rgba(255, 255, 255, 0.4);
$black-shadow: rgba(0, 0, 0, 0.4);
$black-shadow-1: rgba(0, 0, 0, 0.5);

$light-shadow: rgba(0, 0, 0, 0.1);
$medium-light-shadow: rgba(0, 0, 0, 0.25);
$medium-shadow: rgba(190, 190, 190, 0.5);
$dark-shadow: rgba(150, 150, 150, 0.75);

$white-50: rgba(255, 255, 255, 0.50);
$white-90: rgba(255, 255, 255, 0.90);
//Color names generated from http://chir.ag/projects/name-that-color/#

/////////////// COLOR NAMES ///////////////

//Grays and Whites
$Abbey: #46494D;
$Alabaster-2: #fafafa;
$Alabaster-light: #F8F8F8;
$Alabaster: #F9F9F9;
$alto-gray: #D8D8D8;
$alto: #D4D4D4;
$alto-white: #E0E0E0;
$annual-gray: #989898;
$Bitter: #878882;
$black: #000000;
$blackhaze: #eaecec;
$bombay: #ADB2B6;
$boulder: #767676;
$cararra2: #f4f3f0;
$cararra: #edebe6;
$cloud: #c7c5c0;
$concrete: #F3F3F3;
$coral-reef: #C5B6A1;
$dark-gray: #2d3e47;
$davy-gray: #555;
$dove-gray-shaft: #726F6F;
$dove-gray: #6E6E6E;
$Dusty-Gray2: #9e9b9b;
$dusty-gray: #999;
$dusty-gray3: #9A9A9A;
$dusty-gray4: #959595;
$dusty-gray5: #979797;
$ebony-clay: #243239;
$egg-white: #fff0c3;
$Emperor: #4F4F4F;
$faded-daffodil: #fff9ce;
$faded-gray: color.adjust($dark-gray, $alpha: -0.5);
$flint: #6c685e;
$Fuscous-gray: #54544F;
$gallery-gray-mine: #808080;
$gallery-gray: #eee;
$gray-2: #929292;
$gray-abbey: #919090;
$gray-line: #dddddd;
$gray: #888888;
$graychateau: #AAADAE;
$gunsmoke: #838c8c;
$half-colonial-white: #FDF4D7;
$light-gray-color: #EEEEEE;
$lightgray: #d3d3d3;
$Mako: #3D4347;
$mako: #444d50;
$mercury: #e5e5e5;
$mine-shaft-dark: #333;
$mine-shaft-gray: #252525;
$moon-mist: #D7D7C1;
$nevada: #5e6566;
$nobel: #B3BFDE;
$Pale: #f5f3eb;
$pampas-gray: #f1f0ea;
$Pampas: #F6F5F2;
$quarter-spanish-white: #F5EBDC;
$raffia-tan: #ECE1C1;
$river-bed: #495B5C;
$river-bed02: #415058;
$Satin-linen: #E6E2D9;
$Shark: #242727;
$Silver: #CCCCCC;
$silver-chalice-darker: #A0A0A0;
$silver-chalice: #AFAFAF;
$grey01: #b0a9b0;
$grey02: #C3BEB6;
$grey03: #6a757c;
$grey04: #d1cecb;
$grey05: #a7aeb1;
$grey06: #A6ABAF;
$grey07: #ecebe5;
$grey08: #ECECEC;
$silver-light: #CDCDCD;
$silver: #C5C5C5;
$silver2: #B9B9B9;
$silver3: #848484;
$Sirocco: #6E7E7F;
$swirl: #d2d0c6;
$transparent: rgba(0, 0, 0, 0);
$transparent-gray-02: rgba(0, 0, 0, 0.2);
$transparent-gray: rgba(0, 0, 0, 0.3);
$transparent-white: rgba(255, 255, 255, 0.5);
$tundora-gray: #444;
$tundora: #414141;
$Westar: #E2E0DC;
$white: #FFFFFF;
$Zanah: #ECF4E6;
$black-transparent: rgba(0, 0, 0, 0.2);
$grey:#BBB;
$hintOfRed: #F5F2F2;
$gainsboro: #dcdcdc;
$dim-gray: #636262;
$tasman: #BCBDBA;
$zambezi: #636161;
$slate-grey-darker:  #556168;
$dirty-white: #d6d6d4;
$white02: #f7f7f7;
$white03: #F2F2F2;
$silver-sand: #B9BEC2;
$white04: #efefef;
$vp-gray: #f2f2f3;
$vp-gray-01: #E5E6E7;
$grimmys-grey: #777;
$vp-gray-8: #56646b;
$vp-gray-10: #2D3E47;
$regent-grey: #939BA0;
$separating-line-grey: #d0d3d5;
$very-dark-gray: #202020;
$navigation-color: #F3F2EE;

//Orange & Yellows
$amber: #FCBD03;
$blaze-orange: #ff7100;
$blaze-orange1: #FF6600;
$bright-sun: #FFD435;
$Buttercup: #f0af0b;
$carrot-orange: #f39821;
$energy-yellow: #F9DE53;
$flush-orange: #ff7300;
$Gamboge: #D99410;
$gold-drop: #e18800;
$golden-tainoi: #ffce6a;
$gold-tips: #E7C116;
$Indochine: #D27C00;
$marigold-yellow: #f9dc7b;
$orange-peel: #F69A00;
$orange: #e78200;
$pirate-gold: #d28900;
$pizazz: #F69600;
$preview-mode-header: #F7A726;
$Saffron2: #f4b43d;
$Saffron: #F1BD30;
$sea-buckthorn: #FDAB2D;
$Selective-Yellow-approx: #FCB100;
$selective-yellow: #f8b602;
$sun: #f8ab20;
$sunglow: #ffd134;
$supernova: #f7c600;
$Tahiti-Gold: #F38C0A;
$tangerine: #E89300;
$Tangerineapprox: #F18900;
$tango-orange: #f37421;
$yellow-sea: #f2ac01;
$zest: #e48919;
$yellowOrange: #FFB644;
$medium-orange: #DD5300;
$VpOrange-WCAG: #E66D00;
$VpOrange-WCAG-hover: #DD5300;
$light-yellow: #f8cc55;
$beige-yellow: #fbe192;

//Blues
$aqua-island: #a2d3dd;
$black-squeeze: #E3F2F5;
$blue-chill: #11A1A2;
$blue-chille: #139FA1;
$blue-java: #1EBCBE;
$Blue-Lagoon2: #008183;
$blue-lagoon: #018c91;
$Blue-Lagoon: #2CA6B5;
$Blue-Stone-2: #006467;
$blue-stone: #015a5c;
$Bondi-Blue: #0093aa;
$bondi-blue: #00A3A9;
$bondi-blue2: #00a6aa;
$boston-blue: #33A1B4;
$bright-turquoise: #0ddadc;
$Cerulean: #0eb7c2;
$chill-blue: #0d8e90;
$chrome-blue: #4D90FE;
$Eastern-Blue-2: #1694a9;
$Eastern-Blue-3: #22ACAF;
$eastern-blue: #1A99AE;
$elm-blue: #21768C;
$Foam: #D2FCFC;
$fountain-blue: #48B3B6;
$genoa-blue: #157E81;
$Hippie-Blue: #50AAAB;
$jagged-ice: #BFE6E6;
$Jagged-ice: #C0DEE9;
$java-blue: #24B4B6;
$java: #28B5BB;
$keppel: #38adb0;
$limed-spruce: #3C5253;
$lochinvar2: #2C8B8D;
$lochinvar: #287c7c;
$mint-tulip: #CAECF3;
$malibu: #7DC8F7;
$monte-carlo: #7fcfd0;
$niagara-blue: #07a7aa;
$oxford-blue: #2d3e47;
$oxford-blue01: #314751;
$oxford-blue-pale: #415058;
$oxford-blue02: #333333;
$oyster-bay: #def2f3;
$Pacific-blue: #00B7BA;
$patina: #609394;
$pelorous-turquoise: #3caeb2;
$pelorous: #42ADB0;
$Pigeon-post: #B3BFDE;
$pulse-teal: #23AFB1;
$Riptide: #86DBE0;
$robins-egg-blue: #06c0c2;
$sherpa-blue: #005154;
$dark-cyan-blue: #042961;

$sinbad: #A9D6D4;
$sky-blue:#97D3D6;
$teal: #007e7f;
$Teal: #008082;
$VpTeal: #148FA7;
$VpTealDarker: #085d6e;
$VpTealDark01: #0E7B8E;
$VpTealDark02: #055963;
$limedSpruce: #2D3E46;
$jaggedIceLight: #C5EBE8;
$iceberg: #D7F1F3;
$summer-sky: #2ABCCD;
$powder-blue: #B0E0E6;
$transparent-blue-challenges: rgba(45, 62, 71, 0.5);
$light-blue: #caecf4;
$light-blue01: #66C7C9;
$light-blue02: #9ddbdd;
$water-leaf: #A8E7E8;
$biscay: #1A356D;
$transparent-cyan: rgba(204, 238, 239, 0.8);
$topic-cyan: rgb(14, 123, 142);;

//Greens
$Atlantis: #A6C12F;
$bold-green: #00c224;
$Cape-Cod: #384042;
$camarone: #006D00;
$Citron: #83A824;
$Citron2: #8DB71F;
$deep-green: #5EA11D;
$deep-sea-green: #0C5563;
$earls-green: #a9c140;
$enterprise-green:#167074;
$japanese-laurel: #136900;
$Jungle-Green: #269B9F;
$jungle-mist: #a6cccd;
$juniper: #798f91;
$Killarney: #3C763D;
$limeade: #6B9304;
$limeade2: #509100;
$persian-green-darker: #01929F;
$persian-green: #009fa1;
$persian-green2: #00969b;
$pine-green: #006A70;
$sushi-green: #84A731;
$dark-moderate-green: #73aa42;
$dark-moderate-green2: #518F2D;
$moderate-green: #94c355;
$lima: #70C216;
$christi: #75C700;
$utilityGreenLight: #bde394;
$utilityGreen :#75c700;
$granny-apple: #D3F5E9;
$VPGreen: #006D00;
$VPGreen-Hover: color.adjust($VPGreen, $lightness: -10%);
$VPGreen-WCAG-AA: #018901;
$SurfieGreen: #127283;

//Reds
$alizarin-crimson: #e62d19;
$Apple-Blossom: #A94442;
$Mauvelous: #F28D94;
$milano-red: #C4150C;
$Monza: #E40615;
$red: #FF0000;
$red01: #DC4B5C;
$red02: #d0021b;
$scarlet: #FA2915;
$Tamarillo: #A91919;
$mojo: #CB4848;
$thunderbird: #D2191E;


/////////////// COLOR MAPPING ///////////////

//////// FONTS /////////
//General
$accent-text-color: $dove-gray;
$dark-text-color: $ebony-clay;
$drop-shadow-color: $dove-gray;
$error-link-color : $red;
$header-dark-color: $Emperor;
$header-main-color: $elm-blue;
$header-text-color: $bondi-blue;
$heading-link-color: $pulse-teal;
$highlight-title-color: $milano-red;
$main-text-color: $dusty-gray;
$navigation-end: $Shark;
$navigation-hover: $Shark;
$navigation-start: $Abbey;
$navigation-submenu: $Foam;
$page-title-color: $oxford-blue;
$regular-text-color: $mine-shaft-gray;
$secondary-text-color: $dusty-gray;
$subtitle-text-color: $pizazz;
$system-entered-messages: $Jagged-ice;


//Tutorial
$tutorial-text-color: $oxford-blue;


//Cards
$card-header-color: $fountain-blue;
$todo-header-title: $alizarin-crimson;


//////// INPUTS /////////
$text-box-glow-effect: $Hippie-Blue;


//////// TRACKERS /////////
$tracker-button-start-default-color: $VPGreen-WCAG-AA;
$tracker-button-end-default-color: $VPGreen;
$tracker-button-start-disabled-color: $silver-chalice;
$tracker-button-end-disabled-color: $silver;
$tracker-button-font-color: $white;
$tracker-yes-button-start-gradient-color: $dove-gray;
$tracker-yes-button-end-gradient-color: $silver-chalice-darker;
$tracker-button-false-start-gradient: $Mauvelous;
$tracker-button-false-end-gradient: $Monza;
$tracker-streak-count: $white;
$tracker-streak-container: #D4D4D4;
$tracker-button-orange-wcag-aa: #E66D00;
$tracker-button-orange2-wcag-aa: #D93300;

//////// CHALLENGES /////////
$challenge-title-color: $milano-red;
$challenge-subtitle-color: $genoa-blue;
$challenge-join-background: $pulse-teal;
$challenge-complete-background-top: $robins-egg-blue;
$challenge-complete-background-bottom: $blue-stone;
$challenge-ribbon-even-background: $raffia-tan;
$challenge-ribbon-odd-background: $coral-reef;
$challenge-data-item-border: $alto-gray;
$challenge-ribbon-current-top: $bright-turquoise;
$challenge-ribbon-current-bottom: $niagara-blue;
$challenge-ribbon-current-fallback: $robins-egg-blue;


//////// PERSONAL CHALLENGES /////////
$personal-challenge-category-divider: $Westar;
$personal-challenge-header: $Fuscous-gray;
$personal-challenge-listing-item-box-shadow-color: $silver-light;
$companychallenge-background-gradientstart: $Alabaster;
$companychallenge-background-gradientstop: $quarter-spanish-white;
$personal-challenge-dashboard-header-color: $black;
$personal-challenge-dashboard-company-challenge-listing-item-color: $black;
$personal-challenge-dashboard-company-challenge-featured-challenge-header-color: $gray;
$personal-challenge-dashboard-company-challenge-listing-item-faded-column-background-color: rgba(255, 255, 255, 0.4);
$personal-challenge-dashboard-company-challenge-rank-icon-color : $sunglow;
$personal-challenge-dashboard-personal-challenge-rank-icon-color : $sunglow;
$company-challenge-listing-item-top-border-color: $pulse-teal;
$personal-challenge-title-color: $pulse-teal;

//Nine Categories
//Todo - figure out what these are, ask Ann
$energy-color: #000;
$focus-color: #000;
$drive-color: #000;


//////// BOX SHADOWS /////////
$box-shadow-style: 0px 0px 20px 0px rgba(150, 150, 150, 0.75);
$inner-box-shadow-style: 0px 0px 15px 0px rgba(200, 200, 200, 0.75);
$box-shadow-gray: 6px 6px 16px $dusty-gray;
$box-shadow-inner-glow: 0px 0px 8px $alto-gray inset;
$box-shadow-navigation: 4px 4px 6px $dusty-gray;
$box-shadow-inner-modal: inset 0px 8px 14px -4px rgba(0,0,0,0.20);
$box-shadow-welcome: 5px 5px 5px 0px rgba(0,0,0,0.2);
$profile-photo-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
$bottom-box-shadow: inset 0 15px 9px -7px $dusty-gray;
$bubble-shadow: 0px 2px 5px -1px rgba(0,0,0,0.90);
$mygoals-navi-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.6);
$well-being-bubble-shadow: 1px 1px 13px 6px rgba(0,0,0,0.39);
$popover-shadow: 0px 2px 20px 2px rgba(150, 150, 150, 0.75);
$ribonn-shadow: 3px 0px 10px 0px rgba(170,170,170,1);
$mygoals-profile-photo-shadow: 2px 2px 10px 0px rgba(0,0,0,0.25);
$mygoals-editgoal-boxshadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.15);
$sevendaychallenge-page-list-header-shadow: 2px 2px 2px -1px rgba(0,0,0,0.25);
$myfriends-list-container-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
$friends-notifications-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
$input-shadow-style: 0px 0px 10px 0px rgba(175,225,230,1);
$input-error-shadow-style: 0px 0px 10px 0px rgba(245,170,170,1);
$homepage-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
$goal-challenge-card-image-background-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.05);
$global-challenge-input-shadow: 0 2px 4px 0 rgba(16, 0, 0, 0.2);
$global-challenge-input-error-shadow: 0 -6px 0 $white, $global-challenge-input-shadow;
$goal-challenge-carousel-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
$game-description-shadow-box: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);


//////// TEXT SHADOWS /////////
$dark-text-shadow: 1px 1px 10px $mine-shaft-dark;
$button-text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
$contest-ribbon-text-shadow: 2px 2px 0px rgba(118, 110, 93, 0.5);
$contest-details-ribbon-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
$mygoals-bubbles-text-shadow: 4px 4px 10px rgba(103, 103, 103, 0.35);
$statements-tab-title-shadow: 2px 2px 4px rgba(150, 150, 150, 0.5);

//////// MY STATS /////////
$stats-text-color: $bold-green;

//////// BUTTONS /////////
$voucher-button-gradient-start: $selective-yellow;
$voucher-button-gradient-end: $tangerine;
$measure-button-gradient-start: $java-blue;
$measure-button-gradient-end: $eastern-blue;
$nameplate-button-border: $pulse-teal;
$nameplate-button-text: $pulse-teal;
$nameplate-button-hover-text: $white;
$nameplate-button-color: $white;
$nameplate-button-top: $java-blue;
$nameplate-button-bottom: $eastern-blue;
$gold-button-top: $sun;
$gold-button-bottom: $zest;
$orange-icon-button-top: $Gamboge;
$orange-icon-button-bottom: $sea-buckthorn;
$orange-icon-button-shadow: $tango-orange;

//////// VALIDATION /////////
$invalid-error: $scarlet;
$button-error-background: rgba(196,21,12,0.92);


//////// TRANSPARENCIES /////////
$mygoals-trans-bg-1: rgba(122,194,198,0.8);
$mygoals-trans-bg-2: rgba(255,255,255,0.9);
$mygoals-trans-bg-3: rgba(224,247,251,0.9);
$info-details: rgba(151,211,214,0.4);
$mygoals-leftnav-trans-1: rgba(185, 222, 223, 0.7);
$mygoals-leftnav-trans-2: rgba(220, 239, 239, 0.7);
$contest-modal-article-bg: rgba(255, 255, 255, 0.7);
$contest-modal-close-button: rgba(0, 0, 0, 0.6);
$text-fade-out-1: rgba(255, 255, 255, 0);
$text-fade-out-2: rgba(255, 255, 255, 1);
//Transparents - Why Set a goal
$wsg-bubble-1: rgba(15, 176, 189, 0.9);
$wsg-bubble-2: rgba(25, 139, 147, 0.9);
$wsg-bubble-3: rgba(0, 133, 161, 0.9);
$wsg-bubble-4: rgba(4, 99, 108, 0.9);
$wsg-bubble-5: rgba(0, 88, 124, 0.9);


//////// CHALLENGE STATES /////////
$challenge-state-details-container: $Satin-linen;
$challenge-state-message: $Mako;
$challenge-options-links: $persian-green;
$challenge-progress-bar-color: $Saffron2;
$challenge-title-line: $Westar;
$challenge-title-box: $Pampas;
$challenge-in-team-message: $Pacific-blue;
$ribbon-title: $white;
$avg-steps: $Pigeon-post;

//////// CONTENTEDITABLE /////////
$contenteditable-border: $silver-light;

//////// SEVEN DAY LEADERBOARD /////////
$sevendaychallenge-leaderbord-homepage-box: $white;
$sevenday-leaderboard-title: $flint;
$sevenday-leaderboard-list-border: $Alabaster-2;
$sevenday-leaderboard-list-user-name: $Bondi-Blue;
$sevenday-leaderboard-list-weeklygoal: $black;
$sevenday-leaderboard-list-userbg1: $bright-sun;
$sevenday-leaderboard-list-userbg2: $egg-white;
$sevenday-leaderboard-list-current-name-steps: $oxford-blue;
$sevendaychallenge-leaderbord-page-link-bg: $cararra2;
$sevendaychallenge-leaderbord-page-link: $Blue-Lagoon2;
$sevendaychallenge-leaderbord-page-link-2: $pulse-teal;
$sevenday-leaderboard-flag: $swirl;
$sevenday-addfriends-text: $black;
$personal-challenge-homepage-title: $river-bed;
$personal-challenge-homepage-btn-start-default-color: $orange-peel;
$personal-challenge-homepage-btn-end-default-color: $selective-yellow;
$personal-challenge-homepage-btn-shadow: $blaze-orange;
$sevendaychallenge-page-title: $black;
$sevendaychallenge-page-titlemessage: $black;
$sevendaychallenge-page-list-header-start-default-color: $persian-green2;
$sevendaychallenge-page-list-header-end-default-color: $bondi-blue2;
$sevendaychallenge-page-leaderboard-list-bg: $white;
$sevendaychallenge-page-leaderboard-list-border: $gallery-gray;
$sevendaychallenge-page-list-lastupload: $tundora;
$sevendaychallenge-page-addfriend-container-border: $annual-gray;
$sevenday-addfriend-text: $boulder;
$sevenday-list-pacer-gradient-bg1: $aqua-island;
$sevenday-list-pacer-gradient-bg2: $black-squeeze;
$sevenday-list-pacer-text: $oxford-blue;

//////// MY FRIENDS /////////
$findfriends-invitesent-btn-bg: $white;
$findfriends-invitesent-btn: $orange-peel;
$findfriends-list-border: $Westar;
$findfriends-list-hover: $cararra;
$findfriends-list-shadow: $box-shadow-style;
$findfriends-search-bg-focus: $white;
$findfriends-search-bg: $Dusty-Gray2;
$findfriends-search-text-focus: $Dusty-Gray2;
$findfriends-search-text: $white;
$friends-invite-item-border: $Zanah;
$friends-notifications-section-bg: $cararra;
$invitefriends-all-text: $oxford-blue;
$invitefriends-friend-allocated-space: $cararra;
$invitefriends-friend-allocated-space: $nobel;
$invitefriends-friends-item-border-active: $Riptide;
$invitefriends-friends-item-border-pending: $cloud;
$invitefriends-friends-item-border: $Pampas;
$invitefriends-link-text: $bondi-blue;
$invitefriends-new-text: $sea-buckthorn;
$invitefriends-search-bg: $white;
$invitefriends-search-border: $silver-chalice;
$invitefriends-search-error-shadow: $input-error-shadow-style;
$invitefriends-search-error-text: $Monza;
$invitefriends-search-shadow: $input-shadow-style;
$invitefriends-search-text-focus: $oxford-blue;
$invitefriends-search-text: $silver-chalice;
$invitefriends-send-btn-1: $Hippie-Blue;
$invitefriends-send-btn-2: $teal;
$invitefriends-send-btn-shadow: $sherpa-blue;
$mutualfriends-text: $oxford-blue;
$myfriends-addfriends-button-end-default-color: $Citron2;
$myfriends-addfriends-button-shadow: $japanese-laurel;
$myfriends-addfriends-button-start-default-color: $limeade2;
$myfriends-invite-accept-btn-color: $white;
$myfriends-invite-accept-btn-end-default-color: $pizazz;
$myfriends-invite-accept-btn-shadow: $flush-orange;
$myfriends-invite-accept-btn-start-default-color: $selective-yellow;
$myfriends-invite-container-border: $gallery-gray;
$myfriends-invite-decline-btn-bg: $white;
$myfriends-invite-decline-btn-color: $orange-peel;
$myfriends-invite-form-bg: $Pampas;
$myfriends-invite-item-bg: $Pampas;
$myfriends-invite-item-bg: $white;
$myfriends-invite-list-showmore-bg: $white;
$myfriends-invite-list-showmore-text: $dusty-gray;
$myfriends-invite-list-showmore: $mercury;
$myfriends-invite-modal-header-bg: $Alabaster;
$myfriends-invite-modal-header-shadow: inset 0 3px 10px -3px rgba(0, 0, 0, .2);
$myfriends-invite-modal-header-title: $oxford-blue;
$myfriends-item-jobtitle-location: $boulder;
$myfriends-item-name: $Bondi-Blue;
$myfriends-list-container-bg: $white;
$myfriends-page-title-border: $silver;
$myfriends-page-title: $oxford-blue;
$myfriends-people-recommend-addfriend-btn-end-default-color: $selective-yellow;
$myfriends-people-recommend-addfriend-btn-shadow: $blaze-orange;
$myfriends-people-recommend-addfriend-btn-start-default-color: $orange-peel;
$myfriends-people-recommend-title: $oxford-blue;
$myfriends-tab-list: $gallery-gray;
$myfriendss-tab-list-active: $Cerulean;
$notifications-nopending-friends-link: $Bondi-Blue;
$notifications-nopending-friends-text: $nevada;
$notifications-pending-friendship-dropdown-bg: $white;
$notifications-pending-friendship-dropdown-border: $Hippie-Blue;
$notifications-pending-friendship-number-shadow: 1px 1px 2px rgba(150, 150, 150, 0.25);
$notifications-pending-friendship-number: $white;
$notifications-section-link: $lochinvar2;
$notifications-section-title: $Cape-Cod;
$suggestedfriends-addfriend-btn-end-default-color: $selective-yellow;
$suggestedfriends-addfriend-btn-shadow: $blaze-orange;
$suggestedfriends-addfriend-btn-start-default-color: $orange-peel;
$suggestedfriends-tab-list-active: $Cerulean;
$suggestedfriends-tab-list: $gallery-gray;

//////// PROFILE PAGE /////////
$profile-cover-overlay: $transparent-gray;
$profile-headings: $white;
$profile-image-container-border: $white;
$profile-change-cover-text: $white;
$profile-basic-info-right-container: $jungle-mist;
$background-beige: #ece9e1;
$background-beige-grey:#E1E0DC;
$edit-pencil-color: $Eastern-Blue-2;
$profile-basic-info-text: $oxford-blue;
$profile-item-edit-input-border: $juniper;
$profile-item-edit-input-text: $oxford-blue;
$profile-item-save-btn-shadow: $tango-orange;
$profile-settings-title-border: $Westar;
$profile-account-settings-container: $Westar;
$profile-section-titles: $oxford-blue;
$profile-progress-container-bg: $Pampas;
$profile-progress-step-title: $Eastern-Blue-2;
$profileprogress-title: $oxford-blue;

//////// HOMEPAGE MOBILE BANNER /////////
$home-mobilebanner-content-container: $white;
$home-mobilebanner-content-title-shadow: 2px 2px 0px rgba(63, 59, 54, 0.7);

//////// SUPPORT DRAWER /////////
$main-support-color: $orange-peel;
$secondary-support-color: $tango-orange;
$support-font-color: $Bondi-Blue;
$support-divider: $silver-light;


//////// ACH DEPOSIT ///////////
$achdeposit-link-color:$Eastern-Blue-3;
$achdeposit-print-btn-color1: $Hippie-Blue;
$achdeposit-print-btn-color2: $Teal;
$achdeposit-print-btn-color3: $Blue-Stone-2;


//////// MENU /////////
$basicHeaderMenuDividerColor: #eaeaea;


//////// V2-SURVEY /////////
$radio-color-default: #979797;
$radio-color-selected: #75C700;
$slider-background: rgba(202, 236, 244, 0.5);
$slider-selected-background: rgba(45, 62, 71, 0.2);
