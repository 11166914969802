/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTSUUniRZcd_wq8DYmIfsw2A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTeXREeHhJi4GEUJI9ob_ak4.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTY0Uz7fbu6RM5MPetubMKio.woff2) format('woff2');
    unicode-range: U+02BC, U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTfzy0yu4vcvNhe7QLuoE8rU.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTc9-ZSaZ3mOOsU9E1f6DGWc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTYZI5FoslwusAsZHK_V0XCI.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTRUOjZSKWg4xBWp_C_qQx0o.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/K88pR3goAWT7BTt32Z01m1tXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/RjgO7rYTmqiVp7vzi-Q5UVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/ttwNtsRpgsxVmgGGmiUOEltXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+02BC, U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/LWCjsQkB6EMdfHrEVqA1KVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/xozscpT2726on7jbcb_pAltXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/59ZRklaO5bWGqF5A9baEEVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/u-WUoqrET9fUeobQW7jkRVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzCUUniRZcd_wq8DYmIfsw2A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzOXREeHhJi4GEUJI9ob_ak4.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzI0Uz7fbu6RM5MPetubMKio.woff2) format('woff2');
    unicode-range: U+02BC, U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzPzy0yu4vcvNhe7QLuoE8rU.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzM9-ZSaZ3mOOsU9E1f6DGWc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzIZI5FoslwusAsZHK_V0XCI.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzBUOjZSKWg4xBWp_C_qQx0o.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxnJcj75aoY2FkN1K7fN5pSM.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxn1Xmpo4ASt4tV2E8fbn9tc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxp0ZClPV6EjNt18zha_DeYY.woff2) format('woff2');
    unicode-range: U+02BC, U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxhvwU2jSZ_W3bMnLe__5hmc.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxlG2YASMDG4K4_XuakzasE8.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxqRhChYxGgJf4PX4Wvk4Rlk.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxo05NJhCxBV64hQBJiatqx0.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/PRmiXeptR36kaC0GEAetxko2lTMeWA_kmIyWrkNCwPc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBrWJ8El2VFcUWHOh_Oq6BA8.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBhLwxQbNcFnuWuPk3YnjToI.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBvDtiuuJNge4XCX2Yxjz0ug.woff2) format('woff2');
    unicode-range: U+02BC, U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBnZwgMdXZ_smNJnor9b37uA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBlvp_UfalQ96C-39B62FvR4.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBkqs5AeeBmydKTXp2rSeyjU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBqg5eI2G47JWe0-AuFtD150.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Oswald Light'), local('Oswald-Light'), url(../fonts/oswald/l1cOQ90roY9yC7voEhngDBJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Oswald Light'), local('Oswald-Light'), url(../fonts/oswald/HqHm7BVC_nzzTui2lzQTDRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Oswald Regular'), local('Oswald-Regular'), url(../fonts/oswald/yg0glPPxXUISnKUejCX4qfesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Oswald Regular'), local('Oswald-Regular'), url(../fonts/oswald/BObL_3V2WX-YniTOi4CsnvesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Oswald Bold'), local('Oswald-Bold'), url(../fonts/oswald/dI-qzxlKVQA6TUC5RKSb3xJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Oswald Bold'), local('Oswald-Bold'), url(../fonts/oswald/bH7276GfdCjMjApa_dkG6RJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/********** FIREFOX  FONTS *************/
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(../fonts/opensans/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Oswald Light'), local('Oswald-Light'), url(../fonts/oswald/HqHm7BVC_nzzTui2lzQTDT8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Oswald Regular'), local('Oswald-Regular'), url(../fonts/oswald/-g5pDUSRgvxvOl5u-a_WHw.woff) format('woff');
}
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Oswald Bold'), local('Oswald-Bold'), url(../fonts/oswald/bH7276GfdCjMjApa_dkG6T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}


/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xJwNa6kgmw9HIHjUBPkzL2f3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xKdGPpWTn2kPFru4k7T0T-v3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xN9i7v7U2vZkHC55NWxtqfn3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xK1ueDcgZDcfV3TWANvdPLj3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xC3qj1XlvLGj0jktnJzWu233rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xC8hAQ4ocbp44gFQt8tMfcH3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xBEur64QvLD-0IbiAdTUNXE.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xBrT1lp9IdZ6Q20XTtjH6Zv3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xIS898UBCVj0FEntoasBk6P3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xBkraXwpsr1uSZUhAAol_4_3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xKTAHfCyYjmaKhz-jw4dXo73rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xP1CsDU87ma3di2VmaZcPSr3rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xBztfhWvsElVqpbWxzFHK633rGVtsTkPsbDajuO5ueQw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xIF8zhZCJ7x9Pr62cRgaVUA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xF1YPouZEKgzpqZW9wN-3Ek.woff) format('woff');
}
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(../fonts/condensed/gk5FxslNkTTHtojXrkp-xONSK5BxN3NFS4EJkViHIqo.woff) format('woff');
}

/* latin */
@font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Vollkorn Regular'), local('Vollkorn-Regular'), url(../fonts/vollkorn/idGKtgpe38okB6bfeHMsLPesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin */
@font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Vollkorn Bold'), local('Vollkorn-Bold'), url(../fonts/vollkorn/wMZpbUtcCo9GUabw9JODeo4P5ICox8Kq3LLUNMylGO4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin */
@font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Vollkorn Italic'), local('Vollkorn-Italic'), url(../fonts/vollkorn/Oiz0tNwvC-Nd29SBQWfWTPk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Vollkorn Regular'), local('Vollkorn-Regular'), url(../fonts/vollkorn/BCFBp4rt5gxxFrX6F12DKvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
}
@font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Vollkorn Bold'), local('Vollkorn-Bold'), url(../fonts/vollkorn/wMZpbUtcCo9GUabw9JODeobN6UDyHWBl620a-IRfuBk.woff) format('woff');
}
@font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Vollkorn Italic'), local('Vollkorn-Italic'), url(../fonts/vollkorn/Oiz0tNwvC-Nd29SBQWfWTBsxEYwM7FgeyaSgU71cLG0.woff) format('woff');
}

