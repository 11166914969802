@use 'libs/genesis-utilities/src/styles/colors.scss' as colors;

.message-center-pane-backdrop {
    width: 100%;
    min-height: 800px;
    height: 100%;
    position: fixed;
    top: 50px;
    z-index: -3;
    background-color: colors.$transparent;
    transition: background-color 0.25s ease;
}
.new-message-center-pane-backdrop {
    width: 100%;
    min-height: 800px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: -3;
    background-color: colors.$transparent;
    transition: background-color 0.25s ease;
}
.show-message-center-pane-backdrop {
    z-index: 900;
    backdrop-filter: blur(3px);
    pointer-events: auto;
    background-color: colors.$black-shadow-1;
}
.show-new-message-center-pane-backdrop {
    z-index: 900;
    pointer-events: auto;
    background-color: colors.$black-shadow-1;
}
.message-center-wrapper {
    position: fixed;
    top: 74px;
    right: 0px;
}
.message-center-wrapper.responsive-message-center-enabled {
    top: auto;
}
.message-center-wrapper .message-center-panel {
    position: sticky;
    max-width: 400px;
    width: min(100vw, 400px);
    top: 0px;
    height: 100vh;
    background-color: #e5e6e7;
    transform: translate3d(100vw, 0, 0);
    margin-right: calc(360px * -1);
    transition:
        transform 0.3s,
        margin-right 0.3s;
}
.message-center-wrapper .show-message-center-panel {
    transform: translate3d(0vw, 0, 0);
    margin-right: 0;
}
.message-center-pane-opened {
    z-index: 991;
}
.new-message-center-wrapper {
    z-index: 990;
    position: fixed;
    top: 0;
    right: 0;
}
.new-message-center-wrapper.responsive-message-center-enabled {
    top: 0;
}
.new-message-center-wrapper .new-message-center-panel {
    position: absolute;
    max-width: 400px;
    width: min(100vw, 400px);
    right: 0;
    height: 105vh;
    background-color: #e5e6e7;
    transform: translate3d(100vw, 0, 0);
    margin-right: calc(360px * -1);
    transition:
        transform 0.3s,
        margin-right 0.3s;
}
.new-message-center-wrapper .show-message-center-panel {
    transform: translate3d(0vw, 0, 0);
    margin-right: 0;
}
